import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import fillersStripe from "../../images/fillersStripe.png"
import faceLiftStripe from "../../images/faceLiftStripe.png"
import correctiveStripe from "../../images/correctiveStripe.png"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "7em 0em",
  },
  details: {
    backgroundColor: "#F8F4F5",
    position: "relative",
  },
  icon: {
    position: "absolute",
    backgroundPosition: "0 0",
    backgroundRepeat: "no-repeat",
    width: "176px",
    height: "176px",
  },
  content: {
    padding: "3em 13em",
    [theme.breakpoints.down("md")]: {
      padding: "6em 2em",
    },
  },
  name: {
    color: "#9D989A",
    textTransform: "uppercase",
    margin: "1em 0em 0.4em 0em",
    paddingBottom: "0px",
  },
  title: {
    color: "#9D989A",
    fontSize: "18px",
    paddingBottom: "2px",
  },
  titleAchieve: {
    color: "#9D989A",
    fontSize: "18px",
    paddingBottom: "2px",
    paddingTop: 20,
  },
  presentationText: {
    fontSize: "1.1rem",
  },
  honorText: {
    fontSize: "0.8rem",
    paddingTop: 20,
  },
  image: {
    width: "351px",
    height: "500px",
  },
  imageHonour: {
    marginTop: 20,
    width: "80px",
    height: "80px",
  },
  fillersStripe: {
    backgroundImage: `url(${fillersStripe})`,
  },
  faceLiftStripe: {
    backgroundImage: `url(${faceLiftStripe})`,
  },
  correctiveStripe: {
    backgroundImage: `url(${correctiveStripe})`,
  },
  imageItem: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  imageHarvard: {
    marginTop: 20,
    width: "180px",
    height: "120px",
  },
}))

const AboutUsCard = ({
  img,
  iconName,
  name,
  title,
  subTitle,
  children,
  id,
  honor1,
  honorText1,
  honor2,
  callumHonorHarvard,
  callumHonorTeaching,
  callumExamen,
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.card} id={id}>
      <Grid container className={classes.details}>
        <div className={`${classes[iconName]} ${classes.icon}`}></div>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
          className={classes.content}
        >
          <Typography component="h2" variant="h4" className={classes.name}>
            {name}
          </Typography>
          <Typography component="h4" variant="p" className={classes.title}>
            {title}
          </Typography>
          {subTitle && (
            <Typography component="h4" variant="p" className={classes.title}>
              {subTitle}
            </Typography>
          )}
          <Typography
            component="p"
            variant="p"
            className={classes.presentationText}
            color="textSecondary"
          >
            {children}
          </Typography>
          {honor1 && (
            <>
              <Typography
                component="h4"
                variant="p"
                className={classes.titleAchieve}
              >
                Achievements
              </Typography>
              <Img
                fluid={honor1}
                className={classes.imageHonour}
                alt={"certificate"}
              ></Img>
              <Typography
                component="p"
                variant="p"
                className={classes.honorText}
                color="textSecondary"
              >
                {honorText1}
              </Typography>
              <br />
            </>
          )}
          {honor2 && (
            <Grid>
              <Grid item>
                <>
                  <br />
                  <Typography
                    component="h4"
                    variant="p"
                    className={classes.titleAchieve}
                  >
                    Achievements
                  </Typography>
                  <br />
                  <Img
                    fluid={callumHonorHarvard}
                    className={classes.imageHarvard}
                    alt={"certificate"}
                  ></Img>
                  <Typography
                    component="p"
                    variant="p"
                    className={classes.honorText}
                    color="textSecondary"
                  >
                    Department of Otolaryngology Harvard Medical School - Callum
                    Faris, M.D. has fulfilled the requirements of professional
                    training as Clinical Fellow in Facial Plastic and
                    Reconstructive Surgery
                  </Typography>
                  <br />
                  <Img
                    fluid={callumHonorTeaching}
                    className={classes.imageHarvard}
                    alt={"certificate"}
                  ></Img>
                  <Typography
                    component="p"
                    variant="p"
                    className={classes.honorText}
                    color="textSecondary"
                  >
                    Award of Excellence in Teaching - Harvard Medical
                    School/Massachusets Eye and Ear - Training Program in
                    Otolaryngology - Head and Neck Surgery - award this
                    certificate of appreciation to - Callum Faris, M.D. for
                    excellence in teaching and mentorship of recidents
                  </Typography>
                  <br />
                  <Img
                    fluid={callumExamen}
                    className={classes.imageHarvard}
                    alt={"certificate"}
                  ></Img>
                  <Typography
                    component="p"
                    variant="p"
                    className={classes.honorText}
                    color="textSecondary"
                  >
                    The International Board For Certification in Facial Plastic
                    and Reconstructive Surgery - Claus D. Walter, M.D. Award for
                    Academic Excellence to - Callum Faris, M.D. for Achieving
                    the Highest score Among the International Candidates on the
                    2013 Certifying Examination
                  </Typography>
                  <br />
                </>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          spacing={1}
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          className={classes.imageItem}
        >
          <Img fluid={img} className={classes.image} alt={name} />
        </Grid>
      </Grid>
    </Card>
  )
}

export default AboutUsCard
