import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import About from "../components/about"
import Layout from "../components/layout"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  background: {
    margin: 0,
    padding: 0,
  },
}))

const AboutUs = ({ intl, data, location }) => {
  const classes = useStyles()
  const banner = data.bannerImage.childImageSharp.fluid
  const susan = data.susan.childImageSharp.fluid
  const callum = data.callum.childImageSharp.fluid
  const hade = data.hade.childImageSharp.fluid
  const hadeHonor = data.hadeHonor.childImageSharp.fluid
  const callumHonorHarvard = data.callumHonorHarvard.childImageSharp.fluid
  const callumHonorTeaching = data.callumHonorTeaching.childImageSharp.fluid
  const callumExamen = data.callumExamen.childImageSharp.fluid

  useEffect(() => {
    const gtagEvent = require("../gTagEventLead")
  }, [])

  return (
    <Layout classes={classes.background}>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "about.SEOtitle" })}
        description={intl.formatMessage({ id: "about.SEO" })}
        path={location.pathname}
      />
      <MainToolbar />
      <About
        bannerImg={banner}
        img1={susan}
        img2={callum}
        img3={hade}
        hadeHonor={hadeHonor}
        callumHonorHarvard={callumHonorHarvard}
        callumHonorTeaching={callumHonorTeaching}
        callumExamen={callumExamen}
      />
    </Layout>
  )
}

export default injectIntl(AboutUs)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "face-nose-sideprofile.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    susan: file(relativePath: { eq: "SusanAboutUS.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    callum: file(relativePath: { eq: "CallumAboutUS.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hade: file(relativePath: { eq: "hadev4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hadeHonor: file(relativePath: { eq: "goldHade.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    callumHonorHarvard: file(relativePath: { eq: "HarvardFellowship.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    callumHonorTeaching: file(relativePath: { eq: "teachingAward.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    callumExamen: file(relativePath: { eq: "examAward.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
